import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  margin-bottom: ${verticalScale(24)}px;
  font-size: ${verticalScale(36)}px;
`;

export const Subtitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
  margin-top: ${verticalScale(16)}px;
`;

export const COLUMN_SEPARATOR_SIZE = horizontalScale(40);

export const CardContainer = styled.View<{ index: number; columns: number }>`
  margin-right: ${({ index, columns }) =>
    (index + 1) % columns === 0 ? 0 : COLUMN_SEPARATOR_SIZE}px;
`;
