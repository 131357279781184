import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';

import { getPlatform } from '~/utils/systemInfo';
import { GroupButtons } from '../GroupButtons/GroupButtons';
import { useBackHandler } from '~/hooks/useBackHandler';

import { Container, Content, Title } from './TizenExitConfirmation.styles';
import { tizenExitConfirmationAtom } from './tizenExitConfirmationAtom';

const FOCUS_KEY = 'tizen-exit-confirmation';

interface ITizenExitConfirmation {
  onCancelExit: () => void;
}

const TizenExitConfirmationComponent = (
  props: ITizenExitConfirmation & FocusableElement
) => {
  const { setFocus, onCancelExit } = props;
  const { t } = useTranslation();

  const setTizenExitConfirmation = useSetAtom(tizenExitConfirmationAtom);

  const exitApp = () => {
    window.tizen.application.getCurrentApplication().exit();
  };

  const closeModal = () => {
    setTizenExitConfirmation(false);
    onCancelExit();
  };

  useBackHandler(closeModal);

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  return createPortal(
    <Container>
      <Content>
        <Title>
          {t('common.exit_confirmation', 'Quer mesmo sair da Queima Diária?')}
        </Title>

        <GroupButtons
          items={[
            {
              text: t('common.go_back', 'Voltar'),
              focusKey: FOCUS_KEY,
              onClick: closeModal,
              expanded: true,
            },
            {
              text: t('common.logout', 'Sair'),
              onClick: exitApp,
              expanded: true,
            },
          ]}
        />
      </Content>
    </Container>,
    document.getElementById('portal')!
  );
};

const TizenExitConfirmationContainer = withFocusable({
  blockNavigationOut: true,
})(TizenExitConfirmationComponent);

export const TizenExitConfirmation = (props: ITizenExitConfirmation) => {
  const tizenExitConfirmation = useAtomValue(tizenExitConfirmationAtom);
  const platform = getPlatform();

  if (tizenExitConfirmation && platform === 'tizen') {
    return <TizenExitConfirmationContainer {...props} />;
  }

  return null;
};
