import React from 'react';
import { Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';
import {
  FocusableElement,
  withFocusable,
  initNavigation,
} from '@noriginmedia/react-spatial-navigation';

import { AppProviders } from './contexts/AppProviders';
import { NetworkStatus } from './components/NetworkStatus/NetworkStatus';
import { TizenExitConfirmation } from './components/TizenExitConfirmation/TizenExitConfirmation';
import { GlobalStyle } from './components/GlobalStyle/GlobalStyle';
import { Routes } from './routes/routes';
import { Crashlytics } from './utils/crashlytics';

const isNativeEnv = Platform.OS !== 'web';

Crashlytics.initialize();

initNavigation({ nativeMode: isNativeEnv });

if (isNativeEnv) {
  enableScreens();
}

const AppComponent = (props: FocusableElement) => {
  const { setFocus } = props;

  return (
    <AppProviders>
      <NetworkStatus>
        <Routes />
      </NetworkStatus>

      <GlobalStyle />
      <TizenExitConfirmation onCancelExit={setFocus} />
    </AppProviders>
  );
};

export const App = withFocusable()(AppComponent);
