import { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useSession } from '~/contexts/SessionContext/SessionContext';
import { login, trackLogin } from '~/services/auth/authApi';

import type { LoginStage } from '../Login.types';

export const useLogin = () => {
  const { onLoginSuccess, persistedEmail } = useSession();
  const { t } = useTranslation();

  const [email, setEmail] = useState(persistedEmail);
  const [password, setPassword] = useState('');
  const [persistEmail, setPersistEmail] = useState(true);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState<LoginStage>(email ? 'password' : 'email');

  const isNextStageDisabled =
    (stage === 'email' && email.length === 0) ||
    (stage === 'password' && password.length === 0);

  const goBackToEmailStage = () => {
    if (stage === 'password') {
      setError('');
      setStage('email');
    }
  };

  const submitLogin = async () => {
    try {
      setError('');
      setLoading(true);

      const { id: token, userId } = await login({ email, password });
      await trackLogin({ sessionId: token, userId, method: 'typing' });
      await onLoginSuccess({
        userToken: token,
        email: persistEmail ? email : undefined,
      });
    } catch (err) {
      if (err?.response?.status === 401) {
        setError(t('login.wrong_credentials', 'E-mail ou senha incorretos'));
      } else {
        setError(t('login.unknown_error', 'Ocorreu um erro desconhecido'));
      }
      setLoading(false);
    }
  };

  const validateEmail = () => {
    const schema = yup.object().shape({
      email: yup.string().email(),
    });

    return new Promise<boolean>((resolve) => {
      schema
        .isValid({ email })
        .then((valid) => resolve(valid))
        .catch(() => resolve(true));
    });
  };

  const handleNextStage = async () => {
    if (isNextStageDisabled || loading) {
      return;
    }

    if (stage === 'email') {
      const emailIsValid = await validateEmail();

      if (emailIsValid) {
        setError('');
        setStage('password');
      } else {
        setError(
          t(
            'login.invalid_email',
            'Para continuar, informe corretamente o seu e-mail.'
          )
        );
      }
      return;
    }

    submitLogin();
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    persistEmail,
    setPersistEmail,
    currentStage: stage,
    isNextStageDisabled,
    isFormLoading: loading,
    formError: error,
    goBackToEmailStage,
    handleNextStage,
  };
};
