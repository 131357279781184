import { atom } from 'jotai';

import { jotaiStore } from '~/contexts/JotaiStoreContext/JotaiStoreContext';

export const tizenExitConfirmationAtom = atom(false);

export const getTizenExitConfirmation = () =>
  jotaiStore.get(tizenExitConfirmationAtom);

export const openTizenExitConfirmationModal = () =>
  jotaiStore.set(tizenExitConfirmationAtom, true);
