import { useState, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { fetchNextProgramContent } from '~/services/programs/programsApi';
import { INextProgramContentResponse } from '~/services/programs/programsApi.types';
import { Crashlytics } from '~/utils/crashlytics';

export const useProgramNextContent = (programId: number) => {
  const [nextProgramContent, setNextProgramContent] =
    useState<INextProgramContentResponse>();

  const nextContent = nextProgramContent?.next?.content;

  useFocusEffect(
    useCallback(() => {
      const getNextProgramContent = async () => {
        try {
          const nextProgramContentData = await fetchNextProgramContent(
            programId
          );
          setNextProgramContent(nextProgramContentData);
        } catch (err) {
          Crashlytics.handleException(err, 'Program UserMediaOrder loading');
        }
      };

      getNextProgramContent();
    }, [programId])
  );

  return { nextProgramContent, nextContent };
};
