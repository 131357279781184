import React, { useCallback, useRef } from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { ListRenderItemInfo } from 'react-native';

import { Card } from '../Card/Card';
import { useSideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';
import { useSetFocusDirection } from '~/hooks/useSetFocusDirection';
import type { FocusDirections } from '~/hooks/useBlockFocusDirection';

import {
  Container,
  Subtitle,
  Title,
  CardContainer,
  CardList,
} from './HorizontalCardList.styles';
import {
  useHorizontalCardList,
  VISIBLE_CARDS,
} from './hooks/useHorizontalCardList';

import type { FocusableButtonRef } from '../FocusableButton/FocusableButton.types';
import type {
  IHorizontalCardList,
  IHorizontalCardListItem,
} from './HorizontalCardList.types';

const HorizontalCardListComponent = (props: IHorizontalCardList) => {
  const { title, isContentBlocked, cards, isContentLazy } = props;

  const { isSideMenuOnScreen, activeMenuItemRef } = useSideMenuOnScreen();
  const firstCardRef = useRef<FocusableButtonRef>(null);

  const { getFlatlistProps, handleCardFocus } =
    useHorizontalCardList(isSideMenuOnScreen);

  useSetFocusDirection(firstCardRef, () => {}, {
    nextFocusLeft: isSideMenuOnScreen ? activeMenuItemRef : firstCardRef,
  });

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<IHorizontalCardListItem>) => {
      let blockFocusDirections: FocusDirections | undefined;
      const isLastCard = index === cards.length - 1;

      if (isLastCard) {
        blockFocusDirections = 'right';
      }

      return (
        <CardContainer>
          <Card
            dense={isSideMenuOnScreen}
            name={item.name}
            badge={item.badge}
            imageURL={item.imageURL}
            isContentBlocked={isContentBlocked}
            onSelectCard={item.onSelectCard}
            onBecameFocused={(element) => {
              item.onFocus?.(element);
              handleCardFocus({ cardIndex: index });
            }}
            timesWatched={item.timesWatched}
            blockFocusDirections={blockFocusDirections}
            lazyImage={isContentLazy || index > VISIBLE_CARDS}
            innerRef={index === 0 ? firstCardRef : undefined}
          />
          {!!item.subtitle && (
            <Subtitle dense={isSideMenuOnScreen}>{item.subtitle}</Subtitle>
          )}
        </CardContainer>
      );
    },
    [
      cards.length,
      handleCardFocus,
      isContentBlocked,
      isContentLazy,
      isSideMenuOnScreen,
    ]
  );

  return (
    <Container>
      <Title sideMenuOnScreen={isSideMenuOnScreen}>{title}</Title>
      <CardList
        keyExtractor={keyExtractor}
        data={cards}
        renderItem={renderItem}
        sideMenuOnScreen={isSideMenuOnScreen}
        {...getFlatlistProps()}
      />
    </Container>
  );
};

const keyExtractor = (item: IHorizontalCardListItem) => item.id.toString();

export const HorizontalCardList = withFocusable()(HorizontalCardListComponent);
