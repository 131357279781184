import React, { memo, useCallback } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { Card } from '../Card/Card';
import { useSideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';

import {
  CardContainer,
  COLUMN_SEPARATOR_SIZE,
  Subtitle,
  Title,
} from './GridCardList.styles';

import type { IGridCardList, IGridCardListItem } from './GridCardList.types';

const GridCardListComponent = (props: IGridCardList) => {
  const {
    title,
    isContentBlocked,
    cards,
    onBecameFocused,
    columns,
    focusKey,
    hasTVPreferredFocus,
    customCardWidth,
    lazyContentLine,
    innerRef,
    contentContainerStyle,
    ListEmptyComponent,
    onLayout,
    hideTitle,
  } = props;

  const { isSideMenuOnScreen } = useSideMenuOnScreen();

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<IGridCardListItem>) => (
      <CardContainer index={index} columns={columns}>
        <Card
          customWidth={customCardWidth}
          dense={isSideMenuOnScreen}
          focusKey={index === 0 ? focusKey : undefined}
          hasTVPreferredFocus={index === 0 ? hasTVPreferredFocus : undefined}
          lazyImage={
            lazyContentLine ? index >= columns * lazyContentLine : false
          }
          isContentBlocked={isContentBlocked}
          imageURL={item.imageURL}
          onSelectCard={item.onSelectCard}
          onBecameFocused={onBecameFocused}
          timesWatched={item.timesWatched}
          name={item.name}
          badge={item.badge}
          blockFocusDirections={
            index === cards.length - 1 ? 'right' : undefined
          }
        />
        {!!item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
      </CardContainer>
    ),
    [
      cards.length,
      columns,
      customCardWidth,
      focusKey,
      hasTVPreferredFocus,
      isContentBlocked,
      isSideMenuOnScreen,
      lazyContentLine,
      onBecameFocused,
    ]
  );

  return (
    <FlatList
      ref={innerRef}
      data={cards}
      keyExtractor={keyExtractor}
      numColumns={columns}
      showsVerticalScrollIndicator={false}
      onLayout={onLayout}
      columnWrapperStyle={
        columns > 1 ? { marginBottom: COLUMN_SEPARATOR_SIZE } : undefined
      }
      contentContainerStyle={[
        // eslint-disable-next-line react-native/no-inline-styles
        { flexGrow: 1 },
        contentContainerStyle,
      ]}
      ListHeaderComponent={() => (hideTitle ? null : <Title>{title}</Title>)}
      ListEmptyComponent={ListEmptyComponent}
      renderItem={renderItem}
    />
  );
};

const keyExtractor = (item: IGridCardListItem) => item.id.toString();

export const GridCardList = memo(GridCardListComponent);
