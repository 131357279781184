import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const Container = styled.View`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.overlay};
  z-index: 15;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.View`
  background-color: ${({ theme }) => theme.colors.popupBackground};
  padding: ${verticalScale(32)}px;
  width: ${verticalScale(500)}px;
  border-radius: ${verticalScale(8)}px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
  margin-bottom: ${verticalScale(36)}px;
  text-align: center;
`;

export const ActionsRow = styled.View`
  flex-direction: row;
`;
