import { RefObject } from 'react';
import { NavigationContainerRef } from '@react-navigation/native';

import { Datadog } from '~/configs/datadog';

interface CrashlyticsUser {
  id: string;
  email: string;
}

interface CrashlyticsNavigation {
  from: string | undefined;
  to: string | undefined;
}

export const Crashlytics = {
  initialize() {
    Datadog.initialize();
  },
  setUser(user: CrashlyticsUser) {
    Datadog.setUser(user.id, user.email);
  },
  clearUser() {
    Datadog.clearUser();
  },
  handleException(error: any, context: string) {
    Datadog.addError(error, context);
  },
  setupNavigation(navigationRef: RefObject<NavigationContainerRef>) {
    // Only used for native navigation
    Datadog.setupViewsTracking(navigationRef);
  },
  handleNavigation(navigation: CrashlyticsNavigation) {
    // Custom tracking for web navigation only
    if (navigation.to) {
      Datadog.startView(navigation.to);
    }
  },
};
