import React, { useEffect, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { GridCardList } from '~/components/GridCardList/GridCardList';
import { IGridCardListItem } from '~/components/GridCardList/GridCardList.types';
import { searchPrograms } from '~/services/programs/programsApi';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import { Crashlytics } from '~/utils/crashlytics';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { SideMenuNavigationProp } from '~/routes/routes.types';
import { horizontalScale } from '~/utils/layout';
import { HORIZONTAL_SAFE_ZONE, VERTICAL_SAFE_ZONE } from '~/utils/constants';

import {
  SearchFilter,
  SEARCH_INPUT_KEY,
} from './components/SearchFilter/SearchFilter';
import {
  ContentRow,
  EmptyListContainer,
  EmptyListText,
  Sidebar,
} from './Search.styles';

import type { OptionType } from './components/SearchFilter/SearchFilter';

const INITIAL_FILTERS = new Set<number>([]);

const CUSTOM_CARD_WIDTH = horizontalScale(328);

const SearchComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState<IGridCardListItem[]>([]);
  const navigation = useNavigation<SideMenuNavigationProp<'Search'>>();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [selectedObjectives, setSelectedObjectives] = useState(INITIAL_FILTERS);
  const [selectedDifficulties, setSelectedDifficulties] =
    useState(INITIAL_FILTERS);

  const { scrollRef, scrollProps, handleElementFocus } =
    useScrollPostion<FlatList<IGridCardListItem>>();

  useEffect(() => {
    setFocus(SEARCH_INPUT_KEY);
  }, [setFocus]);

  useEffect(() => {
    const getPrograms = async () => {
      try {
        setLoading(true);
        const result = await searchPrograms({
          searchText,
          objectivesFilter: [...selectedObjectives],
          levelsFilter: [...selectedDifficulties],
        });

        const data = result.map((program) => ({
          id: program.id,
          name: program.name,
          imageURL: program.imageCard,
          onSelectCard: () => {
            navigation.navigate('Program', { id: program.id });
          },
        }));

        setPrograms(data);
      } catch (err) {
        Crashlytics.handleException(err, 'Search page filters');
      } finally {
        setLoading(false);
      }
    };

    const timer = setTimeout(() => getPrograms(), 500);

    return () => clearTimeout(timer);
  }, [searchText, selectedObjectives, selectedDifficulties, navigation]);

  const handleSearchInput = (event: any) => {
    if (Platform.OS === 'web') {
      setSearchText(event?.target?.value);
    } else {
      setSearchText(event);
    }
  };

  const onSelectObjective = (id: number) => {
    const newSet = new Set(selectedObjectives);
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);
    setSelectedObjectives(newSet);
  };

  const onSelectDifficulty = (id: number) => {
    const newSet = new Set(selectedDifficulties);
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);
    setSelectedDifficulties(newSet);
  };

  const handleOptionChange = (id: number, type: OptionType) => {
    if (type === 'objetive') {
      onSelectObjective(id);
    } else {
      onSelectDifficulty(id);
    }
  };

  return (
    <ScreenBackground>
      <ContentRow>
        <Sidebar>
          <SearchFilter
            searchText={searchText}
            handleSearchInput={handleSearchInput}
            handleOptionChange={handleOptionChange}
            selectedOptions={{ selectedObjectives, selectedDifficulties }}
          />
        </Sidebar>

        <GridCardList
          innerRef={scrollRef}
          onLayout={scrollProps.onLayout}
          contentContainerStyle={{
            paddingVertical: VERTICAL_SAFE_ZONE,
            paddingLeft: horizontalScale(48),
            paddingRight: HORIZONTAL_SAFE_ZONE,
          }}
          title={`${t('search.results', 'Resultados da busca')} (${
            programs.length
          })`}
          hideTitle={loading}
          cards={loading ? [] : programs}
          onBecameFocused={handleElementFocus}
          columns={3}
          lazyContentLine={4}
          customCardWidth={CUSTOM_CARD_WIDTH}
          ListEmptyComponent={
            loading ? (
              <LoadingIndicator loading={loading} />
            ) : (
              <EmptyListContainer>
                <EmptyListText>
                  {t(
                    'search.no_result',
                    'Não encontramos essa combinação de aulas pra você.'
                  )}
                </EmptyListText>
              </EmptyListContainer>
            )
          }
        />
      </ContentRow>
    </ScreenBackground>
  );
};

export const Search = withFocusable()(SearchComponent);
