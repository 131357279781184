import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const BannerWrapper = styled.View`
  background-color: ${({ theme }) => theme.colors.background};
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const BannerDescription = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(28)}px;
`;
