import styled from 'styled-components/native';
import { FlatList } from 'react-native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import {
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
  CARD_DIMENSIONS,
} from '~/utils/constants';
import type { ISideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';

import type { IHorizontalCardListItem } from './HorizontalCardList.types';

export const Container = styled.View`
  width: 100%;
`;

export const Title = styled.Text<ISideMenuOnScreen>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  padding-left: ${({ sideMenuOnScreen }) =>
    sideMenuOnScreen ? SPACING_FROM_SIDE_MENU : HORIZONTAL_SAFE_ZONE}px;
  font-size: ${verticalScale(36)}px;
`;

export const Subtitle = styled.Text<{ dense?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(27)}px;
  margin-top: ${verticalScale(16)}px;
  width: ${({ dense }) =>
    dense ? CARD_DIMENSIONS.denseWidth : CARD_DIMENSIONS.width}px;
`;

export const CardList = styled(
  FlatList as new () => FlatList<IHorizontalCardListItem>
).attrs<ISideMenuOnScreen>(({ sideMenuOnScreen }) => ({
  contentContainerStyle: {
    paddingTop: verticalScale(24),
    paddingBottom: verticalScale(48),
    paddingRight: HORIZONTAL_SAFE_ZONE,
    paddingLeft: sideMenuOnScreen
      ? SPACING_FROM_SIDE_MENU
      : HORIZONTAL_SAFE_ZONE,
  },
}))<ISideMenuOnScreen>``;

export const SEPARATOR_SIZE = horizontalScale(40);

export const CardContainer = styled.View`
  margin-right: ${SEPARATOR_SIZE}px;
`;
