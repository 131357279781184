import styled from 'styled-components/native';
import QRCode from 'react-native-qrcode-svg';

import qdLogo from '~/assets/img/logo.png';
import { verticalScale, horizontalScale } from '~/utils/layout';
import { Stage } from '~/components/Stage/Stage';

export const Content = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${horizontalScale(240)}px;
`;

export const Instructions = styled.View`
  align-items: flex-start;
`;

export const StyledStage = styled(Stage)`
  margin-top: ${verticalScale(32)}px;
`;

const BaseText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
`;

const ICON_CONTAINER_SIZE = verticalScale(48);

export const IconContainer = styled.View`
  width: ${ICON_CONTAINER_SIZE}px;
  height: ${ICON_CONTAINER_SIZE}px;
  border-radius: ${ICON_CONTAINER_SIZE / 2}px;
  background-color: ${({ theme }) => theme.colors.text.default};
  justify-content: center;
  align-items: center;
  margin-left: ${horizontalScale(16)}px;
  margin-top: ${verticalScale(30)}px;
`;

export const Title = styled(BaseText)`
  font-size: ${verticalScale(36)}px;
`;

export const ButtonContainer = styled.View`
  margin-top: ${verticalScale(48)}px;
  width: ${verticalScale(250)}px;
`;

export const QRCodeCard = styled.View`
  background-color: ${({ theme }) => theme.colors.itemBackground};
  justify-content: center;
  align-items: center;
  border-radius: ${verticalScale(8)}px;
  width: ${verticalScale(400)}px;
  height: ${verticalScale(400)}px;
`;

export const QRCodeHelper = styled(BaseText)`
  margin-top: ${verticalScale(20)}px;
  font-size: ${verticalScale(24)}px;
`;

export const StyledQRCode = styled(QRCode).attrs(({ theme }) => ({
  size: verticalScale(280),
  logo: qdLogo,
  logoMargin: verticalScale(6),
  logoBackgroundColor: theme.colors.text.default,
  quietZone: verticalScale(25),
}))``;

export const StyledStageWithIcon = styled.View`
  flex-direction: row;
`;
