import React, { createContext, useContext, useRef, useCallback } from 'react';
import io from 'socket.io-client';

import { socketUrlProfile } from '~/configs/env';
import { getDeviceLocation, getDeviceInfo } from '~/utils/systemInfo';
import { storage } from '~/utils/storage';
import { USER_TOKEN_KEY } from '~/utils/constants';

import type {
  IDeviceLimitContext,
  IDeviceConnectEventPayload,
} from './DeviceLimitContext.types';

const DeviceLimitContext = createContext({} as IDeviceLimitContext);

export const useDeviceLimit = () => useContext(DeviceLimitContext);

export const DeviceLimitProvider: React.FC = ({ children }) => {
  const devicesSocket = useRef<SocketIOClient.Socket | null>(null);
  const playerSocket = useRef<SocketIOClient.Socket | null>(null);

  const startDevicesSocket = useCallback(
    async (onDeviceLimitReached: () => void) => {
      const location = await getDeviceLocation();
      const deviceInfo = await getDeviceInfo();
      const userToken = await storage.getItem(USER_TOKEN_KEY);

      const SOCKET_PAYLOAD: IDeviceConnectEventPayload = {
        auth: {
          token: `${userToken}`,
        },
        transports: ['websocket'],
        query: {
          location: JSON.stringify({
            city: decodeURI(location?.city),
            country: decodeURI(location?.country),
            countryName: decodeURI(location?.countryName),
            region: decodeURI(location?.region),
            regionName: decodeURI(location?.regionName),
            latitude: decodeURI(location?.latitude),
            longitude: decodeURI(location?.longitude),
          }),
          ip: location.ip,
          os: deviceInfo?.deviceOS,
          brand: deviceInfo?.brand ?? deviceInfo?.deviceOS,
          model: deviceInfo?.modelName,
        },
      };

      devicesSocket.current = io(`${socketUrlProfile}/devices`, SOCKET_PAYLOAD);
      devicesSocket.current?.on('kick', onDeviceLimitReached);
    },
    []
  );

  const startPlayerSocket = useCallback(async () => {
    const userToken = await storage.getItem(USER_TOKEN_KEY);

    playerSocket.current = io(`${socketUrlProfile}/player`, {
      auth: {
        token: `${userToken}`,
      },
      transports: ['websocket'],
      forceNew: true,
    });
  }, []);

  const startSocketConnections: IDeviceLimitContext['startSocketConnections'] =
    useCallback(
      async ({ onDeviceLimitReached }) => {
        startDevicesSocket(onDeviceLimitReached);
        startPlayerSocket();
      },
      [startDevicesSocket, startPlayerSocket]
    );

  const closeSocketConnections = useCallback(() => {
    devicesSocket.current?.removeAllListeners();
    devicesSocket.current?.disconnect();
    playerSocket.current?.removeAllListeners();
    playerSocket.current?.disconnect();
  }, []);

  const onPlayPauseEvent: IDeviceLimitContext['onPlayPauseEvent'] = useCallback(
    (event, { programId, mediaId }) => {
      playerSocket.current?.emit(event, {
        data: {
          mediaId: `${mediaId}` || null,
          programId: `${programId}`,
          type: 'program',
        },
        timestamp: new Date().getTime().toString(),
      });
    },
    []
  );

  return (
    <DeviceLimitContext.Provider
      value={{
        playerSocket,
        startSocketConnections,
        closeSocketConnections,
        onPlayPauseEvent,
      }}
    >
      {children}
    </DeviceLimitContext.Provider>
  );
};
