import { datadogRum } from '@datadog/browser-rum';
import { NavigationContainerRef } from '@react-navigation/native';
import { RefObject } from 'react';

import { getDeviceInfo } from '~/utils/systemInfo';
import {
  isDevelopmentEnv,
  datadogProjectId,
  datadogClientToken,
  envShortName,
  appVersion,
} from '../env';

import { IDatadog } from './types';

const initialize = async () => {
  if (__DEV__ || isDevelopmentEnv()) {
    return;
  }

  datadogRum.init({
    applicationId: datadogProjectId,
    clientToken: datadogClientToken,
    service: 'queima-diaria-tv-web',
    env: envShortName,
    version: appVersion,
    sessionSampleRate: 1,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  const deviceInfo = await getDeviceInfo();
  if (deviceInfo) {
    datadogRum.setGlobalContextProperty('os', {
      name: deviceInfo.deviceOS,
      version: deviceInfo.platformVersion,
    });

    datadogRum.setGlobalContextProperty('device', {
      brand: deviceInfo.brand,
      family: deviceInfo.family,
      model: deviceInfo.modelName,
      screenHeight: deviceInfo.screenHeight,
      screenWidth: deviceInfo.screenWidth,
    });
  }
};

const setUser = (id: string, email: string) => {
  datadogRum.setUser({ id, email });
};

const clearUser = () => {
  datadogRum.clearUser();
};

// Web is handled by startView
const setupViewsTracking = (_ref: RefObject<NavigationContainerRef>) => {};

const startView = (name: string) => {
  datadogRum.startView({ name });
};

const addError = (error: unknown, context: string) => {
  datadogRum.addError(error, { context });
};

export const Datadog: IDatadog = {
  initialize,
  setUser,
  clearUser,
  setupViewsTracking,
  startView,
  addError,
};
