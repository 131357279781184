import React, { useCallback, useState } from 'react';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { useNavigation, useFocusEffect } from '@react-navigation/native';

import { Avatar } from '~/components/Avatar/Avatar';
import { EditIcon } from '~/assets/icons/EditIcon';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { fetchProfiles } from '~/services/profiles/profilesApi';
import { Crashlytics } from '~/utils/crashlytics';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import {
  IProfile,
  IProfileResponse,
} from '~/services/profiles/profilesApi.types';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import { baseAssetsUrl } from '~/configs/env';
import { PlusIcon } from '~/assets/icons/PlusIcon';
import { verticalScale } from '~/utils/layout';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { RootStackNavigationProp } from '~/routes/routes.types';

import {
  Content,
  Profiles,
  Title,
  ProfilesRow,
  ProfileContainer,
  ProfileName,
  StyledFocusableAvatar,
  OverlayAvatar,
  IconContent,
  IconContainer,
} from './ProfileManage.styles';

const FOCUS_KEY = 'done-editing';
const PLUS_ICON_SIZE = verticalScale(30);

const ProfileManageComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const navigation = useNavigation<RootStackNavigationProp<'ProfileManage'>>();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<IProfileResponse>();

  const getProfiles = useCallback(async () => {
    setError(false);
    setLoading(true);

    try {
      setProfiles(await fetchProfiles());
      setLoading(false);
      setFocus(FOCUS_KEY);
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Profile manage loading');
    }
  }, [setFocus]);

  useFocusEffect(
    useCallback(() => {
      getProfiles();
    }, [getProfiles])
  );

  const goBack = () => navigation.goBack();

  const handleAddProfile = () => navigation.navigate('ProfileAdd', {});

  const handleEditProfile = (
    { id, name, avatar }: IProfile,
    parentProfile: boolean
  ) =>
    navigation.navigate('ProfileEdit', {
      id,
      name,
      avatar,
      parentProfile,
    });

  const handleIconColor = (focused: boolean) =>
    focused ? colors.text.active : colors.itemBackground;

  if (error) {
    return (
      <ScreenError
        message={t(
          'profile_manage.error',
          'Não foi possível buscar os perfis.'
        )}
        retry={getProfiles}
      />
    );
  }

  return (
    <ScreenBackground>
      <Content>
        <LoadingIndicator loading={loading} />

        {profiles && !loading && (
          <>
            <FloatingLogo />

            <Profiles>
              <Title>{t('profile_manage.title', 'Editar perfil')}</Title>
              <ProfilesRow>
                {profiles?.parentProfile && (
                  <ProfileContainer>
                    <StyledFocusableAvatar
                      onEnterPress={() =>
                        handleEditProfile(profiles?.parentProfile, true)
                      }
                      onPress={() =>
                        handleEditProfile(profiles?.parentProfile, true)
                      }
                    >
                      {(focused) => (
                        <>
                          <Avatar
                            altText={profiles?.parentProfile?.name}
                            image={`${baseAssetsUrl}/${profiles?.parentProfile?.avatar}`}
                          />
                          <OverlayAvatar isSelected={focused}>
                            <IconContent>
                              <EditIcon fill={handleIconColor(focused)} />
                            </IconContent>
                          </OverlayAvatar>
                        </>
                      )}
                    </StyledFocusableAvatar>
                    <ProfileName numberOfLines={2}>
                      {profiles?.parentProfile?.name}
                    </ProfileName>
                  </ProfileContainer>
                )}

                {profiles?.childProfiles &&
                  profiles?.childProfiles.map((profile, index) => (
                    <ProfileContainer key={index}>
                      <StyledFocusableAvatar
                        onEnterPress={() => handleEditProfile(profile, false)}
                        onPress={() => handleEditProfile(profile, false)}
                      >
                        {(focused) => (
                          <>
                            <Avatar
                              altText={profile?.name}
                              image={`${baseAssetsUrl}/${profile?.avatar}`}
                            />
                            <OverlayAvatar isSelected={focused}>
                              <IconContent>
                                <EditIcon fill={handleIconColor(focused)} />
                              </IconContent>
                            </OverlayAvatar>
                          </>
                        )}
                      </StyledFocusableAvatar>
                      <ProfileName numberOfLines={2}>
                        {profile?.name}
                      </ProfileName>
                    </ProfileContainer>
                  ))}

                {profiles?.childProfiles &&
                  profiles?.childProfiles?.length <
                    Number(profiles?.numberOfChildProfiles) && (
                    <ProfileContainer>
                      <StyledFocusableAvatar
                        onEnterPress={handleAddProfile}
                        onPress={handleAddProfile}
                      >
                        <IconContainer>
                          <PlusIcon
                            width={PLUS_ICON_SIZE}
                            height={PLUS_ICON_SIZE}
                            fill={colors.text.default}
                          />
                        </IconContainer>
                      </StyledFocusableAvatar>
                      <ProfileName numberOfLines={2}>
                        {t('profile_manage.add_profile', 'Adicionar perfil')}
                      </ProfileName>
                    </ProfileContainer>
                  )}
              </ProfilesRow>

              <ActionButton
                focusKey={FOCUS_KEY}
                text={t('common.done', 'Concluído')}
                hasTVPreferredFocus
                onEnterPress={goBack}
                onPress={goBack}
              />
            </Profiles>
          </>
        )}
      </Content>
    </ScreenBackground>
  );
};

export const ProfileManage = withFocusable({ blockNavigationOut: true })(
  ProfileManageComponent
);
