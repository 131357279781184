import React from 'react';

import { ActionButton } from '../ActionButton/ActionButton';

import { Container } from './GroupButtons.styles';

import type { IGroupButtons } from './GroupButtons.types';

export const GroupButtons = (props: IGroupButtons) => {
  const { items } = props;

  return (
    <Container>
      {items.map((button, index) => (
        <ActionButton
          key={`group-button--${index}`}
          innerRef={button.innerRef}
          hasTVPreferredFocus={button.hasTVPreferredFocus}
          style={button.style}
          focusKey={button.focusKey}
          text={button.text}
          onEnterPress={button.onClick}
          rightGap={index !== items.length - 1}
          onPress={button.onClick}
          onBecameFocused={button.onBecameFocused}
          icon={button.icon}
          blockFocusUp={button.blockFocusUp}
          blockFocusRight={button.blockFocusRight}
          blockFocusDown={button.blockFocusDown}
          blockFocusLeft={button.blockFocusLeft}
          onArrowPress={button.onArrowPress}
          expanded={button.expanded}
        />
      ))}
    </Container>
  );
};
