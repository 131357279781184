import React, { useEffect } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { Keyboard } from '~/components/Keyboard/Keyboard';
import { Stage } from '~/components/Stage/Stage';
import { TextField } from '~/components/TextField/TextField';
import { Checkbox } from '~/components/Checkbox/Checkbox';
import { ActionButton } from '~/components/ActionButton/ActionButton';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { RootStackNavigationProp } from '~/routes/routes.types';

import {
  Content,
  LeftColumn,
  KeyboardWrapper,
  StageActions,
  RightColumn,
  Form,
  Title,
  FieldWrapper,
  NavigationArea,
} from './Login.styles';
import { useLogin } from './hooks/useLogin';
import { useLoginFocusProps } from './hooks/useLoginFocusProps';

const FOCUS_KEY = 'login-keyboard';

const LoginComponent = (props: FocusableElement) => {
  const { stealFocus, updateAllSpatialLayouts, setFocus } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const navigation = useNavigation<RootStackNavigationProp<'Login'>>();
  const { t } = useTranslation();

  const {
    email,
    setEmail,
    password,
    setPassword,
    persistEmail,
    setPersistEmail,
    currentStage,
    isNextStageDisabled,
    isFormLoading,
    formError,
    goBackToEmailStage,
    handleNextStage,
  } = useLogin();

  const {
    memorizeCheckboxProps,
    passwordToggleProps,
    forgotPasswordProps,
    previousStageProps,
    nextStageProps,
  } = useLoginFocusProps({ setFocus, currentStage });

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  const navigateToLoginByPhone = () => {
    navigation.navigate('LoginByPhone');
  };

  const navigateToForgotPassword = () => {
    navigation.navigate('ForgotPassword', { email });
  };

  return (
    <ScreenBackground>
      <FloatingLogo />

      <Content>
        <LeftColumn>
          <KeyboardWrapper>
            <Keyboard
              text={currentStage === 'email' ? email : password}
              onChangeText={currentStage === 'email' ? setEmail : setPassword}
              type={currentStage === 'email' ? 'email' : 'default'}
              focusKey={FOCUS_KEY}
              hasTVPreferredFocus
            />
          </KeyboardWrapper>

          <NavigationArea>
            <ActionButton
              text={t('login.access_by_phone', 'Acessar com o celular')}
              onPress={navigateToLoginByPhone}
              onEnterPress={navigateToLoginByPhone}
              blockFocusLeft
            />
          </NavigationArea>
        </LeftColumn>

        <RightColumn>
          <Form>
            <Title>{t('login.access_title', 'Acessar a Queima Diária')}</Title>
            {currentStage === 'email' ? (
              <>
                <Stage number={1} variant="active">
                  {t('login.type_your_email', 'Digite o seu e-mail:')}
                </Stage>

                <FieldWrapper>
                  <TextField
                    placeholder={t('login.email', 'E-mail')}
                    value={email}
                    error={formError}
                  />
                </FieldWrapper>
                <Checkbox
                  label={t('login.memorize_email', 'Memorizar e-mail')}
                  checked={persistEmail}
                  onPress={setPersistEmail}
                  {...memorizeCheckboxProps}
                />
              </>
            ) : (
              <>
                <Stage number={1}>{email}</Stage>
                <Stage number={2} variant="active">
                  {t('login.type_your_password', 'Digite a sua senha:')}
                </Stage>

                <FieldWrapper>
                  <TextField
                    placeholder={t('login.password', 'Senha')}
                    value={password}
                    error={formError}
                    secureTextEntry
                    passwordToggleProps={passwordToggleProps}
                  />
                </FieldWrapper>

                <ActionButton
                  text={t('common.forgot_password', 'Esqueci a senha')}
                  onPress={navigateToForgotPassword}
                  onEnterPress={navigateToForgotPassword}
                  {...forgotPasswordProps}
                />
              </>
            )}
          </Form>

          <StageActions>
            <ActionButton
              expanded
              rightGap
              disabled={currentStage === 'email'}
              text={t('common.go_back', 'Voltar')}
              onPress={goBackToEmailStage}
              onEnterPress={goBackToEmailStage}
              {...previousStageProps}
            />
            <ActionButton
              expanded
              text={
                isFormLoading
                  ? t('login.submit_loading', 'Entrando...')
                  : t('common.next', 'Próximo')
              }
              onPress={handleNextStage}
              onEnterPress={handleNextStage}
              disabled={isNextStageDisabled || isFormLoading}
              {...nextStageProps}
            />
          </StageActions>
        </RightColumn>
      </Content>
    </ScreenBackground>
  );
};

export const Login = withFocusable({ blockNavigationOut: true })(
  LoginComponent
);
