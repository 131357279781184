import { verticalScale, horizontalScale } from './layout';

export const USER_TOKEN_KEY = '@Queima::user_token';

export const VERTICAL_SAFE_ZONE = verticalScale(60);

export const HORIZONTAL_SAFE_ZONE = horizontalScale(90);

export const SPACING_FROM_SIDE_MENU = horizontalScale(56);

export const FLOATING_LOGO_HEIGHT = verticalScale(120);

export const PROFILE_SCREENS_PADDING = FLOATING_LOGO_HEIGHT + verticalScale(40);

export const BANNER_HEIGHT = verticalScale(460);

export const CARD_DIMENSIONS = {
  aspectRatio: 0.565,
  width: horizontalScale(405),
  denseWidth: horizontalScale(388),
};
