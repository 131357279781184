import { useState, useLayoutEffect, useMemo } from 'react';
import { NetworkManager } from 'tizen-tv-webapis';

import { getPlatform } from '~/utils/systemInfo';
import { Crashlytics } from '~/utils/crashlytics';

const useTizenNetwork = () => {
  const [networkMod, setNetworkMod] = useState<NetworkManager | null>(null);
  const [isConnected, setIsConnected] = useState(true);

  useLayoutEffect(() => {
    if (getPlatform() === 'tizen') {
      import('tizen-tv-webapis').then((mod) => setNetworkMod(mod.network));
    }
  }, []);

  useLayoutEffect(() => {
    if (!networkMod) {
      return;
    }

    let listenerId = -1;
    try {
      listenerId = networkMod.addNetworkStateChangeListener(function (value) {
        if (value === networkMod.NetworkState.GATEWAY_DISCONNECTED) {
          setIsConnected(false);
        } else if (value === networkMod.NetworkState.GATEWAY_CONNECTED) {
          setIsConnected(true);
        }
      });
    } catch (err) {
      Crashlytics.handleException(err, 'Tizen add network listener');
    }

    return () => {
      try {
        networkMod.removeNetworkStateChangeListener(listenerId);
      } catch (err) {
        Crashlytics.handleException(err, 'Tizen remove network listener');
      }
    };
  }, [networkMod]);

  useLayoutEffect(() => {
    if (!networkMod) {
      return;
    }

    try {
      const gatewayStatus = networkMod.isConnectedToGateway();
      setIsConnected(gatewayStatus);
    } catch (err) {
      Crashlytics.handleException(err, 'Tizen gateway check');
    }
  }, [networkMod]);

  return { isConnected };
};

const useWebOSNetwork = () => {
  const [isConnected, setIsConnected] = useState(true);

  useLayoutEffect(() => {
    if (getPlatform() !== 'webOS') {
      return;
    }

    window.webOSDev.connection.getStatus({
      onSuccess: function (res) {
        if (res.isInternetConnectionAvailable === false) {
          setIsConnected(false);
        } else {
          setIsConnected(true);
        }
      },
      onFailure: function (err) {
        Crashlytics.handleException(err, 'WebOS network check');
      },
      subscribe: true,
    });
  }, []);

  return { isConnected };
};

export const useGlobalNetwork = () => {
  const tizenNetwork = useTizenNetwork();
  const webosNetwork = useWebOSNetwork();

  const isConnected = useMemo(() => {
    const plaform = getPlatform();
    if (plaform === 'tizen') {
      return tizenNetwork.isConnected;
    } else if (plaform === 'webOS') {
      return webosNetwork.isConnected;
    }
    // Always connected when running on browser
    return true;
  }, [tizenNetwork.isConnected, webosNetwork.isConnected]);

  return { isConnected };
};
