import styled, { css } from 'styled-components/native';
import QRCode from 'react-native-qrcode-svg';

import qdLogo from '~/assets/img/logo.png';
import { verticalScale } from '~/utils/layout';

export const ContentTitle = styled.Text<{
  spaceTop?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(54)}px;
  font-weight: 700;
  ${({ spaceTop }) => {
    if (spaceTop) {
      return css`
        margin-top: ${verticalScale(24)}px;
      `;
    }
  }}
`;

export const ContentBody = styled.Text<{
  bold?: boolean;
  spaceBottom?: boolean;
  font40?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(32)}px;
  line-height: ${verticalScale(42)}px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  margin-top: ${verticalScale(10)}px;
  ${({ spaceBottom }) => {
    if (spaceBottom) {
      return css`
        margin-bottom: ${verticalScale(30)}px;
      `;
    }
  }}
  ${({ font40 }) => {
    if (font40) {
      return css`
        font-size: ${verticalScale(40)}px;
      `;
    }
  }}
`;

export const QRCodeCard = styled.View`
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledQRCode = styled(QRCode).attrs(({ theme }) => ({
  size: verticalScale(240),
  logo: qdLogo,
  logoMargin: verticalScale(2),
  logoBackgroundColor: theme.colors.text.default,
  quietZone: verticalScale(25),
}))``;
