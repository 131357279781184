import React, { useCallback, useMemo, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

import { FocusItems } from '~/types';
import { GroupButtons } from '~/components/GroupButtons/GroupButtons';
import { RootStackParams } from '~/routes/routes.types';
import { getSources } from '~/utils/functions';
import { verticalScale } from '~/utils/layout';
import { PlusIcon } from '~/assets/icons/PlusIcon';
import { MinusIcon } from '~/assets/icons/MinusIcon';
import { useFavoritePrograms } from '~/contexts/MyListContext/MyListContext';
import { Crashlytics } from '~/utils/crashlytics';
import {
  addProgramToFavorites,
  removeProgramfromFavorites,
} from '~/services/favoritePrograms/favoriteProgramsApi';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { fetchUserPlayerAccess } from '~/services/user/userApi';

import type { IBannerAction } from './BannerActions.types';

const ICON_DIMENSIONS = verticalScale(22);

export const BannerActions = (props: IBannerAction) => {
  const { program, handleFocus, toggleFavoriteRef, hasNextContent } = props;

  const { t } = useTranslation();
  const { colors } = useTheme();
  const navigation = useNavigation<StackNavigationProp<RootStackParams>>();

  const [loading, setLoading] = useState<boolean>(false);
  const toggleMyListProps = useBlockFocusDirection(
    hasNextContent ? [] : 'right'
  );
  const { favoritePrograms, addFavorite, removeFavorite } =
    useFavoritePrograms();

  const isProgramFavorite = useMemo(
    () => favoritePrograms.some((favProgram) => favProgram.id === program.id),
    [favoritePrograms, program.id]
  );

  const toggleFavoriteProgram = useCallback(async () => {
    try {
      setLoading(true);
      if (isProgramFavorite) {
        await removeProgramfromFavorites(program.id);
        removeFavorite(program.id);
      } else {
        await addProgramToFavorites(program.id);
        addFavorite(program);
      }
    } catch (err) {
      Crashlytics.handleException(err, 'ProgramService addFav/removeFav');
    } finally {
      setLoading(false);
    }
  }, [isProgramFavorite, program, removeFavorite, addFavorite]);

  const FavIcon = useCallback(
    (focused) => {
      const iconColor = focused ? colors.text.active : undefined;
      if (loading) {
        return (
          <ActivityIndicator
            color={colors.spinner.primary}
            size={ICON_DIMENSIONS}
          />
        );
      }

      if (isProgramFavorite) {
        return (
          <MinusIcon
            width={ICON_DIMENSIONS}
            height={ICON_DIMENSIONS}
            fill={iconColor}
          />
        );
      }

      return (
        <PlusIcon
          width={ICON_DIMENSIONS}
          height={ICON_DIMENSIONS}
          fill={iconColor}
        />
      );
    },
    [colors.spinner.primary, colors.text.active, isProgramFavorite, loading]
  );

  return (
    <GroupButtons
      items={[
        {
          text: t('program.how_it_works', 'Como Funciona'),
          focusKey: FocusItems.HowWorks,
          onClick: () =>
            navigation.navigate('MoreInfo', {
              id: program.id,
              how: program.metadata.generalVision,
              who: program.instructors[0],
            }),
          onBecameFocused: handleFocus,
          hasTVPreferredFocus: true,
        },
        {
          text: t('common.presentation', 'Apresentação'),
          focusKey: 'program-' + FocusItems.Presentation,
          onClick: async () => {
            const { allowed } = await fetchUserPlayerAccess();
            if (!allowed) {
              navigation.navigate('DeviceLimit');
              return;
            }
            navigation.navigate('Player', {
              sources: getSources({
                streamMetadataHls: program.streamMetadata.hls,
                streamMetadataHd: program.streamMetadata.hd,
              }),
              programId: program.id,
            });
          },
          onBecameFocused: handleFocus,
        },
        {
          style: {
            flexDirection: 'row',
          },
          text: isProgramFavorite
            ? t('program.remove_from_list', 'Remover da minha lista')
            : t('program.add_to_list', 'Adicionar à minha lista'),
          icon: (focused: boolean) => FavIcon(focused),
          onClick: toggleFavoriteProgram,
          onBecameFocused: handleFocus,
          innerRef: toggleFavoriteRef,
          ...{ ...toggleMyListProps },
        },
      ]}
    />
  );
};
