import { isReadyRef, navigationRef } from '~/routes/rootNavigation';
import { getPlatform } from '~/utils/systemInfo';
import { openTizenExitConfirmationModal } from '~/components/TizenExitConfirmation/tizenExitConfirmationAtom';

export const backButtonHandler = () => {
  if (!isReadyRef?.current || !navigationRef?.current) {
    return;
  }

  const canGoBack = navigationRef.current.canGoBack();

  if (canGoBack) {
    navigationRef.current.goBack();
    return;
  }

  const platform = getPlatform();

  if (platform === 'tizen') {
    openTizenExitConfirmationModal();
  } else if (platform === 'webOS') {
    window.webOS.platformBack();
  }
};
