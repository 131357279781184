import { useEffect, useRef, useState } from 'react';

import { intersectionObserver } from '../utils/intersectionObserver';

interface IUseInViewProps {
  skip?: boolean;
  threshold?: number | number[];
}

export const useInView = ({ skip, threshold }: IUseInViewProps = {}) => {
  const ref = useRef<any>(null);
  const [inView, setInView] = useState(skip || false);

  useEffect(() => {
    const element = ref.current;

    if (!element || skip) {
      return;
    }

    let unobserve: (() => void) | undefined = () => {
      intersectionObserver.unobserve(element, observerOptions);
    };

    const observerOptions = { threshold };

    intersectionObserver.addEnterCallback(element, () => {
      setInView(true);
      unobserve?.();
      unobserve = undefined;
    });
    intersectionObserver.observe(element, observerOptions);

    return () => {
      unobserve?.();
    };
  }, [skip, threshold]);

  return { ref, inView };
};
