import { AppRegistry } from 'react-native';

import { App } from './App';
import { registerTizenKeys } from './configs/registerTizenKeys';

/**
 * Same name on app.json
 */
const APP_NAME = 'QueimaDiária';

AppRegistry.registerComponent(APP_NAME, () => App);

registerTizenKeys();

if (typeof window !== 'undefined') {
  require('smoothscroll-polyfill').polyfill();

  if ('ResizeObserver' in window === false) {
    import('@juggle/resize-observer').then((module) => {
      (window as any).ResizeObserver = module.ResizeObserver;
    });
  }
}

AppRegistry.runApplication(APP_NAME, {
  rootTag: document.getElementById('root'),
});
