import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const EmptyList = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const EmptyListTitle = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(36)}px;
  margin-bottom: ${verticalScale(40)}px;
`;

export const EmptyListText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(24)}px;
`;

export const ButtonContainer = styled.Text`
  margin-top: ${verticalScale(48)}px;
`;
