import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import videojs, { VideoJsPlayer } from 'video.js';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import {
  createWatchSession,
  registerProgressWatchListenner,
  PlayerTypesEnum,
} from '@queimadiaria/content-watch-tracker';

import { PlayIcon } from '~/assets/icons/PlayIcon';
import { PauseIcon } from '~/assets/icons/PauseIcon';
import { BackwardIcon } from '~/assets/icons/BackwardIcon';
import { ForwardIcon } from '~/assets/icons/ForwardIcon';
import { ClosedCaptionsIcon } from '~/assets/icons/ClosedCaptionsIcon';
import {
  getFormatedPlayerTime,
  localisationToTrackLanguage,
} from '~/utils/functions';
import { useMediaButtons } from '~/hooks/useMediaButtons';
import { BackButton } from '~/components/BackButton/BackButton';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { PlayerError } from '~/components/PlayerError/PlayerError';
import { CaptionsMenu } from '~/components/PlayerControls/components/CaptionsMenu';
import { PlayerControls } from '~/components/PlayerControls/PlayerControls';
import { usePlayerControls } from '~/components/PlayerControls/hooks/usePlayerControls';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { getVideoWatchedRangesAndTime } from '~/utils/getVideoWatchedRangesAndTime';
import type { IClosedCaption } from '~/services/programs/programsApi.types';
import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';
import { useDeviceLimit } from '~/contexts/DeviceLimitContext/DeviceLimitContext';

import { useFetchClosedCaptions } from './hooks/useFetchClosedCaptions';
import { useTrackFullWatchedContent } from './hooks/useTrackFullWatchedContent';
import { useHandleBuffering } from './hooks/useHandleBuffering';
import { useUpdateCaptionPreference } from './hooks/useUpdateCaptionPreference';
import { useSelectedTextTrack } from './hooks/useSelectedTextTrack';
import { Container } from './Player.styles.web';

import './VideoJS.css';

const initialOptions: videojs.PlayerOptions = {
  autoplay: true,
  controls: false,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

const FOCUS_KEY = 'player-play-pause-button';

const PlayerComponent = ({ setFocus }: FocusableElement) => {
  const navigation = useNavigation<RootStackNavigationProp<'Player'>>();
  const route = useRoute<RootStackRouteProp<'Player'>>();
  const { sources, programId, mediaId } = route.params;
  const { t } = useTranslation();
  const { onPlayPauseEvent, playerSocket } = useDeviceLimit();

  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [playerProgress, setPlayerProgress] = useState<number>(0);
  const [remainingTime, setRemaining] = useState<string>('');
  const [playerError, setPlayerError] = useState<boolean>(false);
  const [selectedTextTrack, setSelectedTextTrack] = useSelectedTextTrack();

  const {
    isControlsActive,
    captionsMenuOpened,
    handleCustomActivity,
    getCaptionsTriggerProps,
    getCaptionsMenuProps,
  } = usePlayerControls();
  const { closedCaptions } = useFetchClosedCaptions(mediaId);
  const { updateCaptionPreference } = useUpdateCaptionPreference();
  const { onBufferStart, onBufferEnd } = useHandleBuffering(setLoading);
  const { handleFullWatchedContent } = useTrackFullWatchedContent({
    mediaId,
    programId,
  });

  const playerRef = useRef<VideoJsPlayer | null>(null);
  const videoNodeRef = useRef<HTMLVideoElement>(null);
  const mediaLoaded = useRef(false);
  const textTracks = useMemo(
    () =>
      closedCaptions?.map((closedCaption) => ({
        id: String(closedCaption.id),
        src: closedCaption.url,
        srcLang: localisationToTrackLanguage(closedCaption.language),
        label: closedCaption.title,
      })),
    [closedCaptions]
  );

  const goBack = useCallback(() => {
    onPlayPauseEvent('stop', { programId, mediaId });
    navigation.goBack();
  }, [mediaId, navigation, onPlayPauseEvent, programId]);

  const togglePlayPause = useCallback(() => {
    setIsPlaying((state) => {
      const newState = !state;

      if (newState) {
        videoNodeRef.current?.play();
      } else {
        videoNodeRef.current?.pause();
      }

      return newState;
    });
  }, []);

  const onBackwardPress = useCallback(() => {
    const player = playerRef.current;
    player?.currentTime(Math.max(0, Math.floor(player?.currentTime() - 30)));
  }, []);

  const onForwardPress = useCallback(() => {
    const player = playerRef.current;
    player?.currentTime(
      Math.min(player?.duration(), Math.floor(player?.currentTime() + 30))
    );
  }, []);

  const onProgress = useCallback(async () => {
    if (mediaLoaded.current) {
      onBufferEnd();
    }

    const player = playerRef.current;
    const now = Math.floor(player?.currentTime() || 0);
    const total = Math.floor(player?.duration() || 0);
    const progress = (now * 100) / total;
    const { ranges, time } = getVideoWatchedRangesAndTime(player);

    setPlayerProgress(progress);
    setRemaining(getFormatedPlayerTime(total - now));

    try {
      await registerProgressWatchListenner(
        {
          playerTimes: {
            getCurrentTime: () => now,
            mediaDuration: total,
          },
          watchedRanges: ranges,
          watchedRangesTime: time,
        },
        playerSocket?.current
      );
    } catch (error) {}

    handleFullWatchedContent(progress);
  }, [handleFullWatchedContent, onBufferEnd, playerSocket]);

  const onFinish = useCallback(() => {
    setPlayerProgress(0);
    setRemaining('');
    goBack();
  }, [goBack]);

  const updateTextTrack = useCallback(
    (targetTrack: IClosedCaption | null) => {
      const remoteTextTracks = playerRef.current?.remoteTextTracks();
      if (!remoteTextTracks) {
        return;
      }

      if (targetTrack === null) {
        updateCaptionPreference(null);
        for (let i = 0; i < remoteTextTracks.length; i++) {
          const track = remoteTextTracks[i];
          track.mode = 'disabled';
        }
        setSelectedTextTrack('');
        return;
      }

      updateCaptionPreference(targetTrack.language);
      const track = remoteTextTracks.getTrackById(String(targetTrack.id));
      if (track) {
        track.mode = 'showing';
        setSelectedTextTrack(localisationToTrackLanguage(targetTrack.language));
      }
    },
    [setSelectedTextTrack, updateCaptionPreference]
  );

  const onError = useCallback(() => {
    setLoading(true);
    const currentSources = playerRef.current?.currentSources();
    if (!currentSources || currentSources.length <= 1) {
      setPlayerError(true);
      return;
    }

    const currentSource = playerRef.current?.currentSource();
    const newSources = currentSources?.filter(
      (source) => source.src !== currentSource?.src
    );
    playerRef.current?.src(newSources || []);
  }, []);

  const handleInitPlayer = useCallback(() => {
    if (sources.length > 0) {
      playerRef.current = videojs(
        videoNodeRef.current,
        {
          ...initialOptions,
          sources,
        },
        () => {
          playerRef.current?.tech(true).on('retryplaylist', onError);
        }
      );
      playerRef.current?.on('loadeddata', () => {
        mediaLoaded.current = true;
        setLoading(false);
        onPlayPauseEvent('play', { programId, mediaId });
        createWatchSession({
          mediaType: PlayerTypesEnum.PROGRAM,
          programId,
          mediaId,
          mediaLength: playerRef.current?.duration(),
        });
      });
      playerRef.current?.on('waiting', onBufferStart);
      playerRef.current?.on('timeupdate', onProgress);
      playerRef.current?.on('ended', onFinish);
      playerRef.current?.on('error', onError);
    } else {
      setPlayerError(true);
    }
  }, [
    sources,
    onBufferStart,
    onProgress,
    onFinish,
    onError,
    onPlayPauseEvent,
    programId,
    mediaId,
  ]);

  useMediaButtons({
    isPlaying,
    togglePlayPause,
    fastForward: onForwardPress,
    rewind: onBackwardPress,
    onButtonPress: handleCustomActivity,
  });

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  useEffect(() => {
    if (!playerRef.current && videoNodeRef.current && closedCaptions) {
      handleInitPlayer();
    }
  }, [closedCaptions, handleInitPlayer]);

  useEffect(() => {
    return () => {
      playerRef.current?.dispose();
    };
  }, []);

  if (playerError) {
    return <PlayerError retry={goBack} />;
  }

  return (
    <ScreenBackground>
      <Container isControlsActive={isControlsActive}>
        <BackButton
          hidden={!isControlsActive}
          blockFocusDirections="right"
          customAction={goBack}
        />

        <div data-vjs-player>
          <LoadingIndicator loading={loading} />

          <video
            ref={videoNodeRef}
            className="video-js"
            crossOrigin="anonymous"
          >
            {textTracks?.map((textTrack) => (
              <track
                key={textTrack.id}
                kind="captions"
                id={textTrack.id}
                src={textTrack.src}
                srcLang={textTrack.srcLang}
                label={textTrack.label}
                default={selectedTextTrack === textTrack.srcLang}
              />
            ))}
          </video>
        </div>

        {captionsMenuOpened && (
          <CaptionsMenu
            selectedLanguage={selectedTextTrack}
            closedCaptions={closedCaptions}
            onSelect={updateTextTrack}
            {...getCaptionsMenuProps()}
          />
        )}

        <PlayerControls isActive={isControlsActive}>
          <PlayerControls.Button
            onPress={togglePlayPause}
            Icon={isPlaying ? PauseIcon : PlayIcon}
            iconScaling={0.8}
            focusKey={FOCUS_KEY}
          />
          <PlayerControls.Button
            onPress={onBackwardPress}
            Icon={BackwardIcon}
          />
          <PlayerControls.Button onPress={onForwardPress} Icon={ForwardIcon} />

          {closedCaptions && closedCaptions.length >= 1 && (
            <PlayerControls.Button
              text={t('common.subtitles', 'Legendas')}
              Icon={ClosedCaptionsIcon}
              {...getCaptionsTriggerProps()}
            />
          )}

          <PlayerControls.ProgressBar progress={playerProgress} />

          <PlayerControls.RemainingTime>
            {remainingTime}
          </PlayerControls.RemainingTime>
        </PlayerControls>
      </Container>
    </ScreenBackground>
  );
};

export const Player = withFocusable({ blockNavigationOut: true })(
  PlayerComponent
);
