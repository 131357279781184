import { useEffect, useCallback } from 'react';

import { CommonKeyMap, TizenKeyMap } from '~/utils/controllerKeyMap';
import { getPlatform } from '~/utils/systemInfo';

interface IUseMediaButtonsProps {
  isPlaying: boolean;
  togglePlayPause: () => void;
  fastForward: () => void;
  rewind: () => void;
  onButtonPress: () => void;
}

type ActionsMap = Record<number, () => void>;

export const useMediaButtons = (props: IUseMediaButtonsProps) => {
  const { isPlaying, togglePlayPause, fastForward, rewind, onButtonPress } =
    props;

  const handlePlayPausePress = useCallback(() => {
    togglePlayPause();
    onButtonPress();
  }, [onButtonPress, togglePlayPause]);

  const handleFastForwardPress = useCallback(() => {
    fastForward();
    onButtonPress();
  }, [onButtonPress, fastForward]);

  const handleRewindPress = useCallback(() => {
    rewind();
    onButtonPress();
  }, [onButtonPress, rewind]);

  useEffect(() => {
    const platform = getPlatform();

    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      const keyPressed = event?.which || event?.keyCode;

      const actionsMap: ActionsMap = {
        [TizenKeyMap.MediaPlayPause]: handlePlayPausePress,
        [CommonKeyMap.MediaPlay]: () => !isPlaying && handlePlayPausePress(),
        [CommonKeyMap.MediaPause]: () => isPlaying && handlePlayPausePress(),
        [CommonKeyMap.MediaFastForward]: handleFastForwardPress,
        [CommonKeyMap.MediaRewind]: handleRewindPress,
      };

      actionsMap[keyPressed]?.();
    };

    if (platform === 'tizen') {
      document.body.addEventListener('keydown', handleKeyDown);
    } else {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (platform === 'tizen') {
        document.body.removeEventListener('keydown', handleKeyDown);
      } else {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [
    isPlaying,
    handlePlayPausePress,
    handleFastForwardPress,
    handleRewindPress,
  ]);
};
