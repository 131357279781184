import { useRef } from 'react';
import { FocusableElement } from '@noriginmedia/react-spatial-navigation';

import { useSetFocusDirection } from '~/hooks/useSetFocusDirection';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';

import type { LoginStage } from '../Login.types';

export interface IUseLoginFocusPropsProps {
  setFocus: FocusableElement['setFocus'];
  currentStage: LoginStage;
}

export const useLoginFocusProps = (props: IUseLoginFocusPropsProps) => {
  const { setFocus, currentStage } = props;

  const memorizeCheckboxRef = useRef<FocusableButtonRef>(null);
  const passwordToggleRef = useRef<FocusableButtonRef>(null);
  const forgotPasswordRef = useRef<FocusableButtonRef>(null);
  const previousStageRef = useRef<FocusableButtonRef>(null);
  const nextStageRef = useRef<FocusableButtonRef>(null);

  const isEmailStage = currentStage === 'email';
  const isPasswordStage = currentStage === 'password';

  const memorizeCheckboxProps = useSetFocusDirection(
    memorizeCheckboxRef,
    setFocus,
    {
      nextFocusUp: isEmailStage ? memorizeCheckboxRef : undefined,
      nextFocusRight: isEmailStage ? memorizeCheckboxRef : undefined,
      nextFocusDown: isEmailStage ? previousStageRef : undefined,
    }
  );

  const passwordToggleProps = useSetFocusDirection(
    passwordToggleRef,
    setFocus,
    {
      nextFocusUp: isPasswordStage ? passwordToggleRef : undefined,
      nextFocusDown: isPasswordStage ? forgotPasswordRef : undefined,
      nextFocusLeft: isPasswordStage ? forgotPasswordRef : undefined,
    }
  );

  const forgotPasswordProps = useSetFocusDirection(
    forgotPasswordRef,
    setFocus,
    {
      nextFocusUp: isPasswordStage ? passwordToggleRef : undefined,
      nextFocusRight: isPasswordStage ? passwordToggleRef : undefined,
    }
  );

  const previousStageProps = useSetFocusDirection(previousStageRef, setFocus, {
    nextFocusUp: isEmailStage ? memorizeCheckboxRef : forgotPasswordRef,
  });

  const nextStageProps = useSetFocusDirection(nextStageRef, setFocus, {
    nextFocusUp: isEmailStage ? memorizeCheckboxRef : forgotPasswordRef,
    nextFocusRight: nextStageRef,
  });

  return {
    memorizeCheckboxProps,
    passwordToggleProps,
    forgotPasswordProps,
    previousStageProps,
    nextStageProps,
  };
};
