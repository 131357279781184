import React, { useCallback, useEffect, useState, useRef } from 'react';
import { findNodeHandle } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import type {
  RootStackNavigationProp,
  RootStackRouteProp,
} from '~/routes/routes.types';
import type {
  IBonusProgram,
  IProgramResponse,
} from '~/services/programs/programsApi.types';
import {
  fetchProgram,
  fetchProgramBonus,
} from '~/services/programs/programsApi';
import { fetchUserPlayerAccess } from '~/services/user/userApi';
import { BackButton } from '~/components/BackButton/BackButton';
import { Banner } from '~/components/Banner/Banner';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { FocusItems } from '~/types';
import { getSources } from '~/utils/functions';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import { Crashlytics } from '~/utils/crashlytics';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';
import { isNativeButton } from '~/components/FocusableButton/FocusableButton';
import { useHasAccessToProgram } from '~/hooks/useHasAccessToProgram';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { Card } from '~/components/Card/Card';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { HorizontalCardList } from '~/components/HorizontalCardList/HorizontalCardList';

import { IVideoPlayerProps } from './Program.types';
import {
  ScrollArea,
  Content,
  VideoListContainer,
  NextContentContainer,
  NextContentName,
  NextContentTitle,
} from './Program.styles';
import { BannerActions } from './components/BannerActions/BannerActions';
import { SignatureMessage } from './components/SignatureMessage/SignatureMessage';
import { useProgramNextContent } from './hooks/useProgramNextContent';

const ProgramComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const navigation = useNavigation<RootStackNavigationProp<'Program'>>();
  const route = useRoute<RootStackRouteProp<'Program'>>();
  const { id } = route.params;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [program, setProgram] = useState<IProgramResponse>();
  const [programBonus, setProgramBonus] = useState<IBonusProgram[]>([]);
  const { nextProgramContent, nextContent } = useProgramNextContent(id);

  const toggleFavoriteRef = useRef<FocusableButtonRef>(null);
  const nextContentRef = useRef<FocusableButtonRef>(null);

  const { scrollRef, scrollProps, handleElementFocus, scrollToTop } =
    useScrollPostion();
  const hasAccessToProgram = useHasAccessToProgram(id);

  const fetchData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);

      const [programData, programBonusData] = await Promise.all([
        fetchProgram(`${id}`),
        fetchProgramBonus(`${id}`),
      ]);

      setProgramBonus(programBonusData.filter((p) => p.bonus.streamMetadata));
      setProgram(programData);
      setFocus(FocusItems.HowWorks);
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Program initial loading');
    } finally {
      setLoading(false);
    }
  }, [id, setFocus]);

  const openMedia = async (payload: IVideoPlayerProps) => {
    const { allowed } = await fetchUserPlayerAccess();
    if (!allowed) {
      navigation.navigate('DeviceLimit');
      return;
    }
    navigation.navigate('Player', payload);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!toggleFavoriteRef.current || !nextContentRef.current) {
      return;
    }

    if (
      isNativeButton(toggleFavoriteRef.current) &&
      isNativeButton(nextContentRef.current)
    ) {
      const nodeBtnList = findNodeHandle(toggleFavoriteRef.current);
      const nodeNextLecture = findNodeHandle(nextContentRef.current);

      toggleFavoriteRef.current.setNativeProps({
        nextFocusRight: nodeNextLecture,
      });

      nextContentRef.current.setNativeProps({
        nextFocusLeft: nodeBtnList,
      });
    }
  });

  if (error) {
    return (
      <ScreenError
        message={t(
          'program.error',
          'Não foi possível buscar as aulas do programa.'
        )}
        retry={fetchData}
      />
    );
  }

  return (
    <ScreenBackground>
      <LoadingIndicator loading={loading} />

      <ScrollArea ref={scrollRef as any} {...scrollProps}>
        {program && (
          <Content>
            <Banner
              bannerURL={program.imageBanner}
              logoNameURL={program.imageLogo}
              level={program.levels.name}
              levelId={program.levels.id}
              objective={program.objectives.name}
              duration={program.metadata.meanDuration}
            >
              <BackButton
                handleFocus={scrollToTop}
                blockFocusDirections={'right'}
              />

              <BannerActions
                program={program}
                handleFocus={scrollToTop}
                toggleFavoriteRef={toggleFavoriteRef}
                hasNextContent={!!nextContent}
              />

              {nextContent && (
                <NextContentContainer>
                  <NextContentTitle>
                    {t('program.your_next_class', 'Sua próxima aula')}
                  </NextContentTitle>
                  <Card
                    innerRef={nextContentRef}
                    isContentBlocked={hasAccessToProgram}
                    imageURL={nextContent.metadata.imagePreview}
                    onSelectCard={() =>
                      openMedia({
                        sources: getSources({
                          streamMetadataHls: nextContent?.streamMetadata.hls,
                          streamMetadataHd: nextContent?.streamMetadata.hd,
                        }),
                        mediaId: nextContent?.id,
                        programId: id,
                      })
                    }
                    onBecameFocused={scrollToTop}
                    blockFocusDirections={'up'}
                  />
                  <NextContentName>{nextContent.name}</NextContentName>
                </NextContentContainer>
              )}
            </Banner>

            {hasAccessToProgram && <SignatureMessage />}

            <VideoListContainer>
              <>
                {program.pages.length > 0 &&
                  program.pages[0].modules.map((module, index) => (
                    <HorizontalCardList
                      key={module.id}
                      isContentBlocked={hasAccessToProgram}
                      isContentLazy={index > 1}
                      cards={module.contents.map((content) => ({
                        id: content.id,
                        imageURL: content.metadata.imagePreview,
                        subtitle: content.name,
                        timesWatched: nextProgramContent?.result
                          ? nextProgramContent?.result[content.id]
                          : 0,
                        onSelectCard: () =>
                          openMedia({
                            sources: getSources({
                              streamMetadataHls: content.streamMetadata?.hls,
                              streamMetadataHd: content.streamMetadata?.hd,
                            }),
                            mediaId: content.id,
                            programId: id,
                          }),
                      }))}
                      title={module.name}
                      onBecameFocused={handleElementFocus}
                    />
                  ))}

                {programBonus.length > 0 && (
                  <HorizontalCardList
                    title={t('program.bonus', 'Bônus')}
                    onBecameFocused={handleElementFocus}
                    isContentBlocked={hasAccessToProgram}
                    isContentLazy
                    cards={programBonus.map((p) => ({
                      id: +p.bonusId,
                      imageURL: p.bonus.metadata.imagePreview,
                      subtitle: p.bonus.name,
                      onSelectCard: () =>
                        openMedia({
                          sources: getSources({
                            streamMetadataHls: p.bonus.streamMetadata?.hls,
                            streamMetadataHd: p.bonus.streamMetadata?.hd,
                          }),
                          mediaId: p.bonus.id,
                          programId: id,
                        }),
                    }))}
                  />
                )}
              </>
            </VideoListContainer>
          </Content>
        )}
      </ScrollArea>
    </ScreenBackground>
  );
};

export const Program = withFocusable({ blockNavigationOut: true })(
  ProgramComponent
);
