import React, { memo } from 'react';

import { useInView } from '~/hooks/useInView';

import { ImageWrapper, StyledImage } from './CardImage.styles';
import { ICardImage } from './CardImage.types';

export const CardImage = memo<ICardImage>((props) => {
  const {
    source,
    onError,
    isLazy = false,
    dense,
    customWidth,
    children,
  } = props;

  const { ref, inView } = useInView({ skip: !isLazy });

  const shouldRenderImage = !isLazy || (isLazy && inView);

  return (
    <ImageWrapper ref={ref} dense={dense} customWidth={customWidth}>
      {shouldRenderImage && (
        <StyledImage
          dense={dense}
          customWidth={customWidth}
          source={source}
          resizeMethod="resize"
          resizeMode="cover"
          onError={onError}
        />
      )}
      {children}
    </ImageWrapper>
  );
});
