import { useState, useRef, useCallback, useEffect } from 'react';
import io from 'socket.io-client';

import { socketUrl } from '~/configs/env';
import { Crashlytics } from '~/utils/crashlytics';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { trackLogin } from '~/services/auth/authApi';
import type {
  ISocketDidConnectReceiverEvent,
  ISocketDidConnectSenderEvent,
} from '~/services/user/userApi.types';

export const useQRCodeLogin = () => {
  const { onSocketLoginSuccess } = useSession();

  const socket = useRef<SocketIOClient.Socket | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrCodeValue, setQrCodeValue] = useState('');

  const onSocketDisconnect = useCallback(() => {
    setQrCodeValue('');
  }, []);

  const onSocketDidConnectReceiver = useCallback(
    (data: ISocketDidConnectReceiverEvent) => {
      setQrCodeValue(data.uuid);
    },
    []
  );

  const onSocketDidConnectSender = useCallback(
    async (data: ISocketDidConnectSenderEvent) => {
      try {
        setLoading(true);
        await trackLogin({
          sessionId: data.token,
          userId: data.user.id,
          method: 'scanning',
        });
        await onSocketLoginSuccess(data);
      } catch (err) {
        setLoading(false);
        Crashlytics.handleException(
          err,
          'Socket event - queima::didConnectSender'
        );
      }
    },
    [onSocketLoginSuccess]
  );

  useEffect(() => {
    socket.current = io(socketUrl, {
      transports: ['websocket'],
    });
    socket.current.emit('queima::connectReceiver', 'android-tv-app');

    return () => {
      socket.current?.close();
    };
  }, []);

  useEffect(() => {
    socket.current?.on('disconnect', onSocketDisconnect);
    socket.current?.on('queima::didConnectSender', onSocketDidConnectSender);
    socket.current?.on(
      'queima::didConnectReceiver',
      onSocketDidConnectReceiver
    );

    return () => {
      socket.current?.removeAllListeners();
    };
  }, [
    onSocketDidConnectReceiver,
    onSocketDidConnectSender,
    onSocketDisconnect,
  ]);

  return { qrCodeValue, loading: loading || !qrCodeValue };
};
