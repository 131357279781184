import styled, { css } from 'styled-components/native';

import { FB_BORDER_WIDTH } from '~/components/FocusableButton/FocusableButton.styles';
import { CARD_DIMENSIONS } from '~/utils/constants';
import { CARD_BORDER_RADIUS } from '../Card.styles';
import { ICard } from '../Card.types';

const getCardWidthWithoutBorders = (dense?: boolean) => {
  const baseWidth = dense ? CARD_DIMENSIONS.denseWidth : CARD_DIMENSIONS.width;
  return baseWidth - FB_BORDER_WIDTH * 2;
};

type CardWidth = Pick<ICard, 'dense' | 'customWidth'>;

const cardWidth = css<CardWidth>`
  ${({ dense, customWidth }) => {
    const width = customWidth || getCardWidthWithoutBorders(dense);

    return css`
      width: ${width}px;
      height: ${width * CARD_DIMENSIONS.aspectRatio}px;
    `;
  }}
`;

export const ImageWrapper = styled.View<CardWidth>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.itemBackground};
  border-radius: ${CARD_BORDER_RADIUS}px;
  ${cardWidth}
`;

export const StyledImage = styled.Image<CardWidth>`
  ${cardWidth}
`;
