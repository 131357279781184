import { createGlobalStyle } from 'styled-components';

import GraphikRegular from '~/assets/fonts/graphik/GraphikRegular.woff2';
import GraphikMedium from '~/assets/fonts/graphik/GraphikMedium.woff2';
import GraphikBold from '~/assets/fonts/graphik/GraphikBold.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'GraphikRegular';
    src: local('GraphikRegular'), local('Graphik-Regular'), local('Graphik Regular'),
      url(${GraphikRegular}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'GraphikMedium';
    src: local('GraphikMedium'), local('Graphik-Medium'), local('Graphik Medium'),
      url(${GraphikMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'GraphikBold';
    src: local('GraphikBold'), local('Graphik-Bold'), local('Graphik Bold'),
      url(${GraphikBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
`;
