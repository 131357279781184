import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';

import { FocusItems } from '~/types';
import { LoadingIndicator } from '~/components/LoadingIndicator/LoadingIndicator';
import { HorizontalCardList } from '~/components/HorizontalCardList/HorizontalCardList';
import type {
  IBannerResponse,
  IExternalProgram,
} from '~/services/programs/programsApi.types';
import {
  fetchBanner,
  fetchAllPrograms,
  fetchRecommended,
} from '~/services/programs/programsApi';
import { useFavoritePrograms } from '~/contexts/MyListContext/MyListContext';
import { useSideMenu } from '~/contexts/SideMenuContext/SideMenuContext';
import { Crashlytics } from '~/utils/crashlytics';
import { fetchAllFavoritePrograms } from '~/services/favoritePrograms/favoriteProgramsApi';
import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ScreenError } from '~/components/ScreenError/ScreenError';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import type { SideMenuNavigationProp } from '~/routes/routes.types';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';
import { isNativeButton } from '~/components/FocusableButton/FocusableButton';
import { getTizenExitConfirmation } from '~/components/TizenExitConfirmation/tizenExitConfirmationAtom';

import { ScrollArea, FeaturedProgram } from './Home.styles';
import { useHomeScrollPosition } from './hooks/useHomeScrollPosition';
import { ContentList } from './components/ContentList/ContentList';
import { DynamicBanner } from './components/DynamicBanner/DynamicBanner';
import { dynamicBannerAtom } from './components/DynamicBanner/dynamicBannerAtom';
import {
  formatBannerData,
  formatProgramsResponse,
} from './utils/formatHomeData';

import type { IContentListRow } from './components/ContentList/ContentList.types';

const HomeComponent = (props: FocusableElement) => {
  const { setFocus, stealFocus, updateAllSpatialLayouts } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const { t } = useTranslation();
  const navigation = useNavigation<SideMenuNavigationProp<'Home'>>();
  const { handleElementFocus, getScrollViewProps } = useHomeScrollPosition();

  const bannerActionRef = useRef<FocusableButtonRef>(null);
  const { loadFavoriteList } = useFavoritePrograms();
  const { enableSideMenuFocus } = useSideMenu();

  const setBannerData = useSetAtom(dynamicBannerAtom);
  const [featuredProgram, setFeaturedProgram] = useState<IBannerResponse>();
  const [programs, setPrograms] = useState<IContentListRow[]>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);

      const [
        bannerResponse,
        recommendedResponse,
        favsResponse,
        programsResponse,
        // livesResponse,
      ] = await Promise.all([
        fetchBanner(),
        fetchRecommended(),
        fetchAllFavoritePrograms(),
        fetchAllPrograms(),
        // fetchAllLives(),
      ]);

      const formattedBannerData = formatBannerData({
        isFeatured: true,
        data: bannerResponse[0],
      });
      setBannerData(formattedBannerData);

      setFeaturedProgram(bannerResponse[0]);

      setPrograms([
        ...formatProgramsResponse(recommendedResponse, 'recommended'),
        ...formatProgramsResponse(programsResponse, 'all_programs'),
      ]);

      loadFavoriteList(favsResponse.map((favorite) => favorite.program[0]));

      if (!getTizenExitConfirmation()) {
        setFocus(FocusItems.KnowMore);
      }
    } catch (err) {
      setError(true);
      Crashlytics.handleException(err, 'Home initial loading');
    } finally {
      setLoading(false);
      setTimeout(enableSideMenuFocus, 0);
    }
  }, [enableSideMenuFocus, loadFavoriteList, setFocus, setBannerData]);

  const handleProgramSelect = useCallback(
    (id: number, isLive: boolean = false) => {
      const navigateTo = isLive ? 'Live' : 'Program';
      navigation.navigate(navigateTo, { id });
    },
    [navigation]
  );

  const handleProgramFocus = useCallback(
    (data: IExternalProgram, isFeatured: boolean = false) => {
      const formattedBannerData = formatBannerData({ isFeatured, data });
      setBannerData(formattedBannerData);

      if (isFeatured) {
        if (
          bannerActionRef?.current &&
          isNativeButton(bannerActionRef.current)
        ) {
          bannerActionRef.current.setNativeProps({
            hasTVPreferredFocus: true,
          });
        } else {
          setFocus(FocusItems.KnowMore);
        }
      }
    },
    [setFocus, setBannerData]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return (
      <ScreenError
        message={t('home.error', 'Não foi possível buscar os programas.')}
        retry={fetchData}
      />
    );
  }

  return (
    <ScreenBackground>
      <LoadingIndicator loading={loading} />

      {!loading && (
        <DynamicBanner
          bannerActionRef={bannerActionRef}
          handleProgramSelect={handleProgramSelect}
          handleElementFocus={handleElementFocus}
        />
      )}

      <ScrollArea {...getScrollViewProps()}>
        <FeaturedProgram>
          {featuredProgram && (
            <HorizontalCardList
              title={featuredProgram.name}
              onBecameFocused={handleElementFocus}
              cards={[
                {
                  id: featuredProgram.id,
                  imageURL: featuredProgram.imageCard,
                  name: featuredProgram.name,
                  onSelectCard: () => {},
                  onFocus: () => handleProgramFocus(featuredProgram, true),
                },
              ]}
            />
          )}
        </FeaturedProgram>

        {programs && (
          <ContentList
            contentRows={programs}
            onRowFocus={handleElementFocus}
            onProgramFocus={handleProgramFocus}
            onProgramSelect={handleProgramSelect}
          />
        )}
      </ScrollArea>
    </ScreenBackground>
  );
};

export const Home = withFocusable()(HomeComponent);
