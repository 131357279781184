import React, { createContext, useContext } from 'react';

import { useGlobalNetwork } from '~/hooks/useGlobalNetwork';

import { INetworkContext } from './NetworkContext.types';

const NetworkContext = createContext({} as INetworkContext);

export const useNetwork = () => useContext(NetworkContext);

export const NetworkProvider: React.FC = ({ children }) => {
  const { isConnected } = useGlobalNetwork();

  return (
    <NetworkContext.Provider value={{ isConnected }}>
      {children}
    </NetworkContext.Provider>
  );
};
