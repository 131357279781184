import { useMemo } from 'react';
import { useRoute } from '@react-navigation/native';

import { MENU_ITEMS_MAP } from '~/components/SideMenu/SideMenu';

export interface ISideMenuOnScreen {
  sideMenuOnScreen: boolean;
}

export const useSideMenuOnScreen = () => {
  const { name } = useRoute();

  const routeName = name as keyof typeof MENU_ITEMS_MAP;

  const isSideMenuOnScreen = useMemo(
    () => Object.keys(MENU_ITEMS_MAP).some((key) => key === routeName),
    [routeName]
  );

  return {
    isSideMenuOnScreen,
    activeMenuItemRef: MENU_ITEMS_MAP?.[routeName]?.innerRef,
  };
};
