import React from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';

import { Banner } from '~/components/Banner/Banner';
import { FloatingLogo } from '~/components/FloatingLogo/FloatingLogo';
import { GroupButtons } from '~/components/GroupButtons/GroupButtons';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { fetchUserPlayerAccess } from '~/services/user/userApi';
import { SideMenuNavigationProp } from '~/routes/routes.types';
import { FocusItems } from '~/types';

import { dynamicBannerAtom } from './dynamicBannerAtom';
import { BannerWrapper, BannerDescription } from './DynamicBanner.styles';

import type { IDynamicBanner } from './DynamicBanner.types';

export const DynamicBanner = (props: IDynamicBanner) => {
  const { bannerActionRef, handleElementFocus, handleProgramSelect } = props;

  const { t } = useTranslation();
  const navigation = useNavigation<SideMenuNavigationProp<'Home'>>();

  const knowMoreButtonProps = useBlockFocusDirection('up');
  const presentationButtonProps = useBlockFocusDirection(['right', 'up']);

  const bannerData = useAtomValue(dynamicBannerAtom);

  const handlePresentationPress = async () => {
    if (!bannerData?.previewSources) {
      return;
    }

    const { allowed } = await fetchUserPlayerAccess();
    if (!allowed) {
      navigation.navigate('DeviceLimit');
      return;
    }

    navigation.navigate('Player', {
      sources: bannerData.previewSources,
      programId: bannerData.id,
    });
  };

  if (!bannerData) {
    return null;
  }

  return (
    <>
      <FloatingLogo />

      <BannerWrapper>
        <Banner
          focusable={bannerData.isFeatured}
          bannerURL={bannerData.bannerURL}
          logoNameURL={bannerData.logoNameURL}
          level={bannerData.level}
          levelId={bannerData.levelId}
          objective={bannerData.objective}
          duration={bannerData.duration}
        >
          {bannerData.isFeatured ? (
            <GroupButtons
              items={[
                {
                  text: t('home.know_more', 'Saiba mais'),
                  focusKey: FocusItems.KnowMore,
                  innerRef: bannerActionRef,
                  onClick: () => handleProgramSelect(bannerData.id),
                  onBecameFocused: handleElementFocus,
                  hasTVPreferredFocus: true,
                  ...{ ...knowMoreButtonProps },
                },
                {
                  text: t('common.presentation', 'Apresentação'),
                  focusKey: FocusItems.Presentation,
                  onClick: handlePresentationPress,
                  onBecameFocused: handleElementFocus,
                  ...{ ...presentationButtonProps },
                },
              ]}
            />
          ) : (
            <BannerDescription numberOfLines={2}>
              {bannerData.description.trim()}
            </BannerDescription>
          )}
        </Banner>
      </BannerWrapper>
    </>
  );
};
