import { useCallback, useRef } from 'react';
import { FlatList } from 'react-native';

import { SEPARATOR_SIZE } from '../HorizontalCardList.styles';
import {
  HORIZONTAL_SAFE_ZONE,
  SPACING_FROM_SIDE_MENU,
} from '~/utils/constants';
import { IHorizontalCardListItem } from '../HorizontalCardList.types';

import type { IHandleCardFocus } from './useHorizontalCardList.types';

export const VISIBLE_CARDS = 4;

export const useHorizontalCardList = (denseThreshold?: boolean) => {
  const scrollRef = useRef<FlatList<IHorizontalCardListItem>>(null);

  const lastCardIndex = useRef(0);
  const positionIndex = useRef(0);

  const handleCardFocus = useCallback(
    (props: IHandleCardFocus) => {
      const { cardIndex } = props;

      const lastVisibleCardIndex = VISIBLE_CARDS - 1;
      const indexDiff = cardIndex - lastCardIndex.current;

      if (
        positionIndex.current <= lastVisibleCardIndex &&
        cardIndex > lastCardIndex.current
      ) {
        positionIndex.current += indexDiff;
      } else if (
        positionIndex.current > 0 &&
        cardIndex < lastCardIndex.current
      ) {
        positionIndex.current += indexDiff;
      }

      lastCardIndex.current = cardIndex;

      if (positionIndex.current === 0) {
        const leftOffset = denseThreshold
          ? SPACING_FROM_SIDE_MENU
          : HORIZONTAL_SAFE_ZONE;

        return scrollRef.current?.scrollToIndex({
          index: cardIndex,
          viewPosition: 0,
          viewOffset: leftOffset,
          animated: true,
        });
      }

      if (positionIndex.current > lastVisibleCardIndex) {
        scrollRef.current?.scrollToIndex({
          index: cardIndex,
          viewPosition: 1,
          viewOffset: -HORIZONTAL_SAFE_ZONE + SEPARATOR_SIZE,
          animated: true,
        });
      }
    },
    [denseThreshold]
  );

  return {
    handleCardFocus,
    getFlatlistProps: () => ({
      ref: scrollRef as any,
      horizontal: true,
      showsHorizontalScrollIndicator: false,
    }),
  };
};
